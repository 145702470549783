<script setup lang="ts">
const shortcuts = [
  {
    icon: 'tabler-calendar',
    title: 'カレンダー',
    subtitle: '予定',
    to: { name: 'clients-calendar' },
  },
  {
    icon: 'tabler-layout',
    title: 'ダッシュボード',
    subtitle: 'ダッシュボード分析',
    to: { name: 'clients-dashboard' },
  },
  {
    icon: 'tabler-settings',
    title: '設定',
    subtitle: 'アカウント設定',
    to: { name: 'pages-account-settings-tab', params: { tab: 'account' } },
  },
  {
    icon: 'tabler-help',
    title: 'ヘルプセンター',
    subtitle: 'FAQ & 記事',
    to: { name: 'pages-help-center' },
  },
]
</script>

<template>
  <Shortcuts :shortcuts="shortcuts" />
</template>
